import React, { ReactElement } from 'react'
import { useTranslation } from 'next-i18next'
import Head from 'next/dist/shared/lib/head'
import useDomainAssets from 'shared/hooks/use-domain-assets'
import Header from 'modules/header'

export function SchoolLayout({ children }: React.PropsWithChildren<{}>) {
  const { t } = useTranslation()
  const { domainAssets } = useDomainAssets()

  return (
    <>
      <Head>
        <title>{t('browser_tab_title.school')}</title>
        {domainAssets?.faviconUrl && <link rel="icon" href={domainAssets.faviconUrl} />}
      </Head>
      <Header logoSrc={domainAssets?.logoUrl} />
      <div className="overflow-hidden">
        <div className="mx-auto max-w-screen-2xl px-5 pb-4 lg:pb-9">{children}</div>
      </div>
    </>
  )
}

export const getSchoolLayout = (page: ReactElement) => <SchoolLayout>{page}</SchoolLayout>
